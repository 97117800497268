import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

const Title = styled.h1`
  text-align: center;
  font-size: 40px;
`;

const Image = styled.img`
  max-height: 400px;
  min-width: 100%;
  height: auto;
  display: block;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 22px;
  margin-top: 32px;
  text-align: center;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

const Button = styled(Link)`
  width: 220px;
  margin: 22px auto;
  text-align: center;
`;

function EventsIKobenhavn() {
  return (
    <>
      <SEO
        title="Events i København | Find events på Zooticket"
        description="Leder du efter events i København? Tag et kig her på Zooticket. Vi har samlet begivenhederne og gjort det nemt!"
      />
      <Layout>
        <Wrapper className="event">
          <Title className="title">Find events i København</Title>
          <Image
            width="500"
            height="500"
            className="event__image"
            src="/images/eventscopenhagen.webp"
            alt="Nyhavn i København"
          />
          <Subtitle>
            Leder du efter events i København? Søg efter events i København her
            på Zooticket!
          </Subtitle>
          <Button className="btn w-32" to="/cities/copenhagen">
            Søg events i København
          </Button>
          <P className="event__info_explore">
            Det har aldrig været nemt at have et overblik over, hvad man kan
            opleve, hvor og hvornår. Det skal være slut! Derfor samler vi events
            fra hele landet, med mulighed for at søge ud fra dato, postnumre,
            kategorier og mere!
          </P>
          <P className="event__info_explore">
            København er en by fyldt med events og oplevelser - alle tider på
            året. Er man til theater, er Det kongelige theater et must at
            opleve. Er det musik oplevelser? Så skal du prøve Vega!
          </P>
          <P className="event__info_explore">
            Det kan også være at man blot vil nyde solen udenfor. Hvis I er en
            håndfuld venner eller familie, kan GoBoat virkelig være en
            oplevelse, I København's skønne kanaler.
          </P>
          <a
            style={{ textDecoration: 'underline' }}
            className="event__info_explore"
            href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=85436"
            target="_blank"
            rel="noreferrer"
          >
            Læs mere om GoBoat i København her
          </a>
          <P className="event__info_explore">
            Jeg vil næsten gå dertil og sige, at der ikke er det, der ikke kan
            opleves i København. Tag et kig, her på Zooticket, og vi håber du
            finder frem til lige hvad du søger
          </P>
        </Wrapper>
      </Layout>
    </>
  );
}

export default EventsIKobenhavn;
